<template>
    <div>
      <h1>Daily Check 테스트</h1>
      <button @click="performDailyCheck">Perform Daily Check</button>
      <div v-if="result">
        <h2>결과:</h2>
        <pre>{{ result }}</pre>
      </div>
    </div>
  </template>
  
  <script>
  import DailyCheckModule from '@/lib/DailyCheckModule';
  
  export default {
    data() {
      return {
        result: null,
      };
    },
    methods: {
      async performDailyCheck() {
        try {
          const result = await DailyCheckModule.performDailyCheck();
          console.log('테스트 결과:', result);
          this.result = result;
        } catch (error) {
          console.error('Daily Check 실행 중 오류 발생:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  h1 {
    color: #2c3e50;
  }
  button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #42b983;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  button:hover {
    background-color: #358a62;
  }
  </style>
  